<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>
        <v-snackbar :timeout="callbackResponse.timeout" :color="callbackResponse.color" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <br />
        <v-col class="d-flex" cols="12" sm="12">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <!--<v-btn @click.stop="onDownload" small class="success mx-4">
            <v-icon>get_app</v-icon>
            {{$t('download_to_excel')}}
        </v-btn>-->
            <v-spacer></v-spacer>
        </v-col>
        <div>
          <div class="d-flex py-4">
            <v-spacer></v-spacer>
            <JsonExcelVue :fetch="fetchData" :header="header" is-raw-headers :rawHeaders="rawHeaders">
              <v-btn small class="success mx-4">
                <v-icon>get_app</v-icon>
                {{$t('download_to_excel')}}
              </v-btn>
            </JsonExcelVue>
          </div>
            <v-data-table :headers="columns"
                          :items="rows"
                          :options.sync="options"
                          :server-items-length="totalRecords"
                          :loading="loading"
                          class="elevation-1"
                          height="550px"
                          hide-default-header
                          hide-default-footer>

                <template v-slot:header="props">
                    <thead>
                        <tr class="text-center">
                            <th rowspan="3" class="text-center">{{$t('fiscal_year')}}</th>
                            <th colspan="3">{{$t('total_earnings')}}</th>

                            <th rowspan="3">{{$t('change_usd')}}</th>
                        </tr>
                        <tr class="text-center">
                            <th rowspan="2">{{$t('nrs')}}</th>
                            <th>{{$t('annual_average')}}</th>
                            <th rowspan="2">{{$t('usd')}}</th>
                        </tr>
                        <tr class="text-center">
                            <th>{{$t('exchange_rate')}}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item="props">
                    <tr class="text-center">
                        <td class="text-center border ">
                            <v-text-field dense
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          v-model="props.item.fiscalYear"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.fiscalYear}}</div>
                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.nrs"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.nrs}}</div>

                        </td>

                        <td>
                            <v-text-field dense
                                          v-model="props.item.exchangeRate"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.exchangeRate}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.amountUsd"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.amountUsd}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.change"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.change}}</div>

                        </td>
                    </tr>

                </template>

            </v-data-table>
            <v-row class="mt-7">
                <div class="col-12" style="color: gray; text-align: right">
                    {{$t('source')}} : {{$t('nepal_rastra_bank')}}
                </div>
            </v-row>
        </div>

    </v-content>
</template>

<script>
    import axios from "axios";
    //import sectorForm from "./create";
    //import editSectorForm from "./edit";
    import deleteListData from "@/components/deleteModal";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"

    export default {
        name: "NrbExchange",
        components: {
            //sectorForm,
            //editSectorForm,
            deleteListData,
          JsonExcelVue
        },
        computed: {
            rawHeaders(){
              return`<th>
                <tr>
                  <td rowspan="3">`+
                    this.$t('fiscal_year')
                  +`</td>
                  <td colspan="3">`+
                    this.$t('total_earnings')
                  +`</td>
                  <td rowspan="3">`+
                    this.$t('change_usd')
                  +`</td>
                </tr>
                <tr>
                  <td rowspan="2">`+
                    this.$t('nrs')
                  +`</td>
                  <td>`+
                    this.$t('annual_average')
                  +`</td>
                  <td rowspan="2">`+
                    this.$t('usd')
                  +`</td>
                </tr>
                <tr>
                  <td>`+this.$t('exchange_rate')+`</td>
                </tr>
              </th>`
            },
            breadCrumb() {
                return [
                    {
                        text: this.$t('income_generation_report'),
                        disabled: false,
                        to: '/reportModules/reportIncomeGenerationDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('gross_foreign_exchange_earnings_from_tourism'),
                        disabled: true,
                    },
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                    color: ''
                },
                customHeader: [],
                isCreate: false,
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                deleteFormData: {},
                popout: true,
                loading: false,
                isLoading: false,
                columns: [
                    {
                        label: "Fiscal Year",
                        field: "fiscalYear"
                    },
                    {
                        label: "NRs. (Million)",
                        field: "nrs"
                    },
                    {
                        label: "Exchange Rate",
                        field: "exchangeRate"
                    },
                    {
                        label: "US$ (Million)",
                        field: "amountUsd"
                    },
                    {
                        label: "%Change in US$",
                        field: "change"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "fiscalYear",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                },
              header: ""
            };
        },

        mounted() {
            this.loadItems();
        },
        methods: {
            fetchData() {
              this.header = this.$t('gross_foreign_exchange_earnings_from_tourism')
              const filteredData = this.rows.map(res=>(
                    {
                      fiscalYear:res.fiscalYear,
                      nrs:res.nrs,
                      exchangeRate: res.exchangeRate,
                      amountUsd: res.amountUsd,
                      change: res.change
                    }))

                return filteredData
            },
            async submit(data) {
                let param = {
                    nrs: parseFloat(data.nrs),
                    fiscalYear: data.fiscalYear.toString(),
                    amountUsd: parseFloat(data.amountUsd),
                    exchangeRate: parseFloat(data.exchangeRate),
                    change: data.change.toString()
                }
                await axios.post("NRBExchange/InsertIntoInComeGenerationAsync", param).then(response => {
                    if (response.data.success) {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Income Data added Succcessfully"
                        this.callbackResponse.color = "success"

                    }

                }).catch(response => {
                    this.snackbar = true
                    this.callbackResponse.data = response.data
                    this.callbackResponse.message = "Error Adding Income Data, Please Contact Admin"
                    this.callbackResponse.color = "error"
                });
                this.loadItems();

            },
            async submitEdit(data) {
                let param = {
                    nrs: parseFloat(data.nrs),
                    fiscalYear: data.fiscalYear.toString(),
                    amountUsd: parseFloat(data.amountUsd),
                    exchangeRate: parseFloat(data.exchangeRate),
                    change: data.change.toString(),
                    inComeID: data.inComeID
                }
                await axios.post("NRBExchange/UpdateInComeGenerationAsync", param).then(response => {
                    this.callbackResponse.data = response.data
                    this.snackbar = true
                    this.callbackResponse.message = "Data updated Successfully"
                    this.callbackResponse.color = "success"

                }).catch(response => {
                    this.snackbar = true
                    this.callbackResponse.data = response.data
                    this.callbackResponse.message = "Error Updating Data, Please Contact Admin"
                    this.callbackResponse.color = "error"
                });
                this.loadItems();

            },
            createRemoveArray(index) {
                this.rows.splice(index, 1)
            },
            addTableData() {
                this.rows.push({
                    fiscalYear: '',
                    nrs: '',
                    exchangeRate: '',
                    amountUsd: '',
                    change: '',
                    isEdit: false,
                    isCreate: true,
                })
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.inComeID = props.inComeID;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "NRBExchange/DeleteInComeGenerationAsync/" + props.inComeID;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = {}
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                //console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                axios.post("NRBExchange/GetInComeGenerationListForAdminAsync", param)
                    .then(({ data }) => {
                        this.rows = data.data;
                        this.rows.map(res => {
                            this.$set(res, 'isEdit', false);
                            this.$set(res, 'isCreate', false);
                            return res;
                        })
                        this.totalRecords = data.totalCount;
                        if (this.rows.length == 0) {
                            this.addTableData();
                            this.totalRecords = 1
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }

    ::v-deep .v-text-field, ::v-deep .v-text-field--solo {
        width: 100px !important;
    }

    ::v-deep .v-data-table__wrapper {
        width: 100% !important;
    }

    .border {
        border: 1px solid black !important;
    }

    table, td {
        height: 40px !important;
        border: 1px solid black !important;
        border-collapse: collapse;
    }

    thead {
        background-color: #1976D2;
        color: white;
        z-index: 1;
        top: 0;
        position: sticky;

        th {
            height: 40px !important;
            border: 1px solid !important;
            color: white !important;
            text-align: center !important;
            border-color: white;
            font-size: 15px !important;
        }
    }

    ::v-deep .v-text-field {
        width: 160px !important;
    }
</style>
